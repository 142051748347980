<script setup>
import { ArrowLongRightIcon, EyeIcon } from "@heroicons/vue/24/outline";
import { ref } from "vue";

// import { EyeIcon } from "@heroicons/24/outline";

const props = defineProps({
  noticia: Object,
});

const detalhes = ref(false);

const formatarData = (data) => {
  const dataFormatada = new Date(data + "T00:00:00");
  return dataFormatada.toLocaleDateString("pt-BR");
};

const verDetalhes = () => {
  if (detalhes.value == false) {
    adicionarViews(props.noticia);
  }
  detalhes.value = !detalhes.value;
};

async function adicionarViews(noticia) {
  const axios = require("axios").default;
  axios
    .post("https://vituz.app/api/noticias-site/views", {
      noticia_id: JSON.stringify(noticia.id),
    })
    .then(function (response) {
      // console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
}
</script>

<template>
  <div
    class="w-11/12 py-2 m-auto bg-opacity-50 border-black border-solid rounded-lg shadow-lg bg-igep-medium-gray hover:bg-igep-light-gray md:flex md:w-8/12"
  >
    <div v-if="!detalhes">
      <div class="p-3 ml-2 md:px-5 md:py-0">
        <p class="py-2 text-sm text-left md:pt-3 md:pb-1">
          {{ formatarData(noticia?.data_noticia) }}
        </p>
      </div>
      <div class="md:p-3 md:ml-2 m-auto w-11/12 md:w-[300px]">
        <img :src="noticia?.image" alt="foto" class="object-cover rounded-md" />
      </div>
    </div>
    <div class="flex-col mt-2 ml-5 text-justify sm:w-full md:py-10 md:pr-3">
      <div v-if="!detalhes">
        <p class="text-md md:text-2xl">{{ noticia?.titulo }}</p>
      </div>

      <div class="flex py-3">
        <button
          v-if="!detalhes"
          :href="noticia?.link"
          @click="verDetalhes"
          class="flex px-2 py-1 text-xs text-white rounded-lg hover:bg-blue-400 bg-igep-blue md:text-sm"
        >
          Leia mais
          <span class="mt-0.5 pl-1"
            ><ArrowLongRightIcon class="w-4 h-4"
          /></span>
        </button>
      </div>
      <div v-if="!detalhes" class="flex justify-end w-full">
        <span>
          <div
            class="flex items-end justify-end text-xs text-right text-gray-400 md:text-sm"
          >
            <EyeIcon class="w-6 h-6 px-1 text-gray-400" />
            <p class="my-auto">
              {{ noticia?.count }}
            </p>
          </div>
        </span>
      </div>

      <div v-if="detalhes" class="text-xs md:text-sm">
        <div class="w-11/12 m-auto text-center">
          <p class="text-md md:text-3xl">{{ noticia?.titulo }}</p>
        </div>

        <div class="w-11/12 m-auto md:w-8/12 md:flex md:my-5">
          <img
            :src="noticia?.image"
            alt="foto"
            class="object-cover rounded-md"
          />
        </div>

        <div class="flex justify-end w-11/12 my-3 sm:w-10/12">
          <span class="p-1.5 bg-igep-blue bg-opacity-20 rounded-2xl text-xs">{{
            noticia?.autor
          }}</span>
        </div>

        <div v-html="noticia?.conteudo" class="px-5 text-base"></div>

        <div class="px-5 py-5">
          <button
            :href="noticia?.link"
            @click="verDetalhes"
            class="p-2 text-xs bg-gray-200 rounded-lg md:text-sm"
          >
            Fechar detalhes ^
          </button>
          <div>
            <span>
              <div
                class="flex justify-end text-xs text-right text-gray-400 md:text-sm"
              >
                <EyeIcon class="w-6 h-6 px-1 text-gray-400" />
                <p class="my-auto">
                  {{ noticia?.count }}
                </p>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
