<script setup>
import FooterBar from "@/components/FooterBar.vue";
import NavigationBar from "@/components/NavigationBar.vue";
import NoticiasCard from "@/components/NoticiasCard.vue";
import { onMounted, ref, watch } from "vue";

const noticias = ref([]);

watch(() => {
  noticias;
});

onMounted(() => {
  getNews("https://vituz.app/api/noticias-site?destino=igep");
});

async function getNews(url) {
  const axios = require("axios").default;

  const newUrl = `${url}&destino=igep`
  return axios
    .get(newUrl)
    .then(function (response) {
      noticias.value = response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

const tratarNome = (nome) => {
  if (nome == "pagination.previous") return "Anterior";
  if (nome == "pagination.next") return "Próxima";

  return nome;
};

const changeCss = (ativo) => {
  if (!ativo)
    return "sm:p-3 p-1 bg-blue-50 rounded-md sm:px-4 py-2 mx-1.5 sm:mx-3";
  return "p-1 sm:p-3 bg-blue-500 rounded-md sm:px-4 py-2 mx-1.5 sm:mx-3 sm:text-lg font-bold";
};
</script>

<template>
  <NavigationBar />

  <body class="mt-20 md:mt-32">
    <div v-if="noticias.length == 0">
      <h1 class="py-10 text-xl md:text-4xl b">Carregando Notícias...</h1>
    </div>
    <div v-for="(noticia, index) in noticias.data" :key="noticia[index]" class="my-5">
      <div>
        <NoticiasCard :noticia="noticia" />
      </div>
    </div>
    <div class="flex items-center justify-center py-2 m-auto mt-2 mb-5 text-center rounded-lg">
      <div v-for="link in noticias.links" :key="link.index">
        <div v-if="link.url != null">
          <button @click="getNews(link.url)" :class="changeCss(link.active)">
            {{ tratarNome(link.label) }}
          </button>
        </div>
      </div>
    </div>
  </body>
  <FooterBar />
</template>
