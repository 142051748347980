<script setup>
// @ is an alias to /src
import FooterBar from "@/components/FooterBar.vue";
import NavigationBar from "@/components/NavigationBar.vue";
import { ClipboardDocumentCheckIcon } from "@heroicons/vue/24/outline";

const people = [
  {
    name: "Consolidação do Estatuto Social",
    title: "",
    link: "https://drive.google.com/file/d/1fij_NmDQ_7QQYikOsdOVWig8co052LC9/view?usp=drive_link",
  },
  {
    name: "Ata da 1ª Assembleia Geral Extraordinária de 2024",
    title: "",
    link: "https://drive.google.com/file/d/1WloAWbo4K6JZgYbY2CDqjDD7MRrEL4E7/view?usp=drive_link",
  },
];

const verPDF = (link) => {
  window.open(link, "_blank");
};
</script>

<template>
  <div class="home">
    <NavigationBar msg="" />

    <body>
      <div
        class="grid grid-cols-1 mt-10 border-b-2 sm:mt-28 sm:grid-cols-2 home_img_1 bg-igep-blue border-igep-dark-blue"
      >
        <div class="sm:m-auto">
          <div class="flex py-28">
            <div class="lex-co">
              <h2
                class="text-4xl font-extrabold tracking-tight text-center sm:ml-auto sm:text-center sm:justify-center text-igep-blue sm:text-8xl"
              >
                IGEP Saúde
              </h2>
              <p
                class="mt-2 text-xs leading-8 sm:mt-6 sm:text-xl sm:pb-32 text-igep-dark-blue"
              >
                Instituto de Gestão, Estudos e Pesquisa em Saúde
              </p>
            </div>
            <!-- <div class="w-full home_img_1">
            </div> -->
          </div>
        </div>
        <!-- <div class="w-4/6 col-span-1 pt-32">
          <img class="w-8-12" src="../assets/bg_home.png" alt="Imagem Médica" />
        </div> -->
      </div>
      <div>
        <div class="flex justify-center px-6 m-auto max-w-7xl sm:py-28 lg:px-8">
          <div class="max-w-2xl my-7 sm:my-2 lg:mx-0">
            <h2
              class="text-4xl font-bold tracking-tight text-igep-dark-blue sm:text-5xl"
            >
              Construindo a saúde do futuro
            </h2>
            <p class="mt-6 text-lg leading-8 text-igep-dark-blue">
              Ofertamos serviços e produtos de saúde com excelência na área da
              gestão integrada, ensino e pesquisa para a sociedade.
            </p>
          </div>
          <div class="max-w-2xl mx-auto mt-10 lg:mx-0 lg:max-w-none">
            <div
              class="grid grid-cols-1 text-base font-semibold leading-7 text-white gap-x-8 gap-y-6 sm:grid-cols-2 md:flex lg:gap-x-10"
            ></div>
          </div>
        </div>
      </div>
      <div
        class="mx-auto bg-gradient-to-r from-igep-blue from-10% to-igep-dark-blue to-90%"
      >
        <div
          class="grid grid-cols-1 px-6 sm:grid-cols-2 lg:pr-16 md:w-12/12 lg:11/12"
        >
          <div class="max-w-2xl col-span-1 mx-auto my-7 lg:mx-0">
            <p class="text-4xl font-bold tracking-tight text-white sm:pt-24">
              Transparência IGEP
            </p>
            <p class="mt-3 text-lg font-medium text-igep-light-gray">
              Atuamos de forma clara, promovendo a divulgação das informações
              públicas.
            </p>
            <div class="pt-3">
              <ClipboardDocumentCheckIcon
                class="m-auto w-28 h-28 text-igep-green"
              />
            </div>
          </div>
          <div
            class="max-w-2xl col-span-1 mx-auto mt-10 lg:mx-0 lg:max-w-none sm:pr-16"
          >
            <div
              class="grid grid-cols-1 mb-12 text-base font-semibold leading-7 text-white gap-x-8 gap-y-6 sm:grid-cols-2 md:flex lg:gap-x-10"
            >
              <div>
                <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2">
                  <li
                    v-for="person in people"
                    :key="person.email"
                    class="col-span-1 bg-white divide-y divide-gray-200 rounded-lg shadow"
                  >
                    <div
                      class="flex items-center justify-between w-full p-6 space-x-6"
                    >
                      <div class="flex-1">
                        <div class="flex items-center space-x-3">
                          <h3
                            class="mb-2 text-2xl font-semibold text-igep-dark-blue"
                          >
                            {{ person.name }}
                          </h3>

                          >
                        </div>
                        <p
                          class="mt-1 text-base font-normal text-left text-gray-500"
                        >
                          {{ person.title }}
                        </p>
                        <div class="flex justify-end">
                          <button
                            @click="verPDF(person.link)"
                            class="px-3 py-1 mt-5 text-sm rounded-xl hover:bg-blue-950 text-igep-green bg-igep-dark-blue"
                          >
                            Saiba mais
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div class="">
                  <div
                    class="grid grid-cols-3 px-3 py-2 mt-8 border-2 rounded-md bg-igep-dark-blue border-igep-green"
                  >
                    <div class="col-span-2 pt-2">
                      Clique aqui para outras informações:
                    </div>
                    <div class="col-span-1 mt-1">
                      <router-link
                        to="/transparencia"
                        class="px-2 py-1 text-sm rounded-lg bg-igep-green hover:bg-lime-500 text-igep-dark-blue"
                        >Saiba mais</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div>
        <div class="px-6 mx-auto max-w-7xl sm:py-28 lg:px-8">
          <div class="max-w-2xl mx-auto lg:mx-0">
            <h2
              class="text-4xl font-bold tracking-tight text-igep-dark-blue sm:text-6xl"
            >
              Algum titulo
            </h2>
            <p class="mt-6 text-lg leading-8 text-igep-dark-blue">
              Algum texto -> Anim aute id magna aliqua ad ad non deserunt sunt.
              Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat
              veniam occaecat fugiat aliqua.
            </p>
          </div>
          <div class="max-w-2xl mx-auto mt-10 lg:mx-0 lg:max-w-none">
            <div
              class="grid grid-cols-1 text-base font-semibold leading-7 text-white gap-x-8 gap-y-6 sm:grid-cols-2 md:flex lg:gap-x-10"
            ></div>
          </div>
        </div>
      </div> -->
    </body>
    <FooterBar />
  </div>
</template>

<style scoped>
.back_img_1 {
  background: url("../assets/back_img_1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.home_img_1 {
  background: url("../assets/home/home_img_1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
```
