<script setup>
// @ is an alias to /src
import FooterBar from "@/components/FooterBar.vue";
import NavigationBar from "@/components/NavigationBar.vue";
import TransparenciaCard from "@/components/TransparenciaCard.vue";
import { DocumentArrowDownIcon } from "@heroicons/vue/24/outline";
import { onMounted, ref, watch } from "vue";

let municipios = ref([]);
let tipos_documento = ref([]);
let infos_cards = ref([]);
let filtered_cards = ref([]);

watch(() => {
  infos_cards;
  filtered_cards;
});

onMounted(() => {
  getNews("https://vituz.app/api/transparencia-igep-site");
});

const form = {
  nome: "",
  municipio: "",
  tipo_documento: "",
};

const filtrar = () => {
  if (form.nome != "") {
    filtered_cards.value = infos_cards?.value.data.filter(
      (card) =>
        card?.titulo?.includes(form.nome) ||
        card?.descricao?.includes(form.nome)
    );
  } else if (form.municipio != "") {
    filtered_cards.value = infos_cards?.value.data.filter((card) =>
      card?.local?.includes(form.municipio)
    );
  } else if (form.tipo_documento != "") {
    filtered_cards.value = infos_cards?.value.data.filter((card) =>
      card?.tipo.nome?.includes(form.tipo_documento)
    );
  } else {
    filtered_cards.value = infos_cards.value.data;
  }
  form.nome = "";
  form.municipio = "";
  form.tipo_documento = "";
};
const getMunicipios = () => {
  const array = infos_cards?.value.data.map((card) => card.local);
  municipios = array.filter((valor, indice, self) => {
    return self.indexOf(valor) === indice;
  });
};

const getTiposDocumento = () => {
  const array = infos_cards?.value.data.map((card) => card?.tipo?.nome);
  tipos_documento = array.filter((valor, indice, self) => {
    return self.indexOf(valor) === indice;
  });
};

async function getNews(url) {
  const axios = require("axios").default;

  return axios
    .get(url)
    .then(function (response) {
      infos_cards.value = response.data;
      getMunicipios();
      getTiposDocumento();
      filtrar();
    })
    .catch(function (error) {
      console.log(error);
    });
}

const tratarNome = (nome) => {
  if (nome == "pagination.previous") return "Anterior";
  if (nome == "pagination.next") return "Próxima";

  return nome;
};

const changeCss = (ativo) => {
  if (!ativo) return "p-3 bg-blue-50 rounded-md px-4 py-2 mx-3";
  return "p-3 bg-blue-500 rounded-md px-4 py-2 mx-3 text-lg font-bold";
};

const Arquivos = [
  {
    nome: "Consolidação do Estatuto Social",
    link: "https://drive.google.com/file/d/1fij_NmDQ_7QQYikOsdOVWig8co052LC9/view?usp=drive_link",
    descricao: "Confira aqui",
  },
  {
    nome: "Balanço Patrimonial de 2023",
    link: "https://drive.google.com/file/d/1qmHwexilO8JJ6_iDSv8sh-0pHy6bXPOV/view?usp=drive_link",
    descricao: "Confira aqui",
  },
  {
    nome: "Ata da 1ª Assembleia Geral Extraordinária de 2024",
    link: "https://drive.google.com/file/d/1WloAWbo4K6JZgYbY2CDqjDD7MRrEL4E7/view?usp=drive_link",
    descricao: "Confira aqui",
  },
];

const verPDF = (link) => {
  // const pdfUrl = require('@/assets/pdfs/estatuto-igep.pdf');
  // console.log(link);
  window.open(link, "_blank");
  // window.open(require('@/assets/estatuto-social.pdf'), '_blank');
};
</script>

<template>
  <NavigationBar />

  <body class="flex mx-auto mt-20 md:mt-32">
    <div class="flex flex-col w-full mx-auto">
      <form
        class="mx-auto mt-5 mb-10 rounded-lg bg-igep-dark-blue sm:h-32 bg-opacity-90 md:w-8/12 p-7"
      >
        <div class="m-auto sm:flex">
          <div class="px-1 sm:w-5/12">
            <label
              class="block mb-2 text-xs font-bold tracking-wide text-white uppercase"
              for="grid-doc-name"
            >
              Nome do documento
            </label>
            <input
              v-model="form.nome"
              @change="filtrar"
              class="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 border border-gray-200 rounded appearance-none bg-gray-50 focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-first-name"
              type="text"
            />
          </div>

          <div class="px-1 sm:w-5/12">
            <label
              class="block mb-2 text-xs font-bold tracking-wide text-white uppercase"
              for="grid-municipio"
            >
              Município
            </label>
            <div>
              <select
                v-model="form.municipio"
                class="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 border border-gray-200 rounded appearance-none bg-gray-50 focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option
                  v-for="municipio in municipios"
                  :key="municipio"
                  :value="municipio"
                  @change="filtrar"
                >
                  {{ municipio }}
                </option>
              </select>
            </div>
          </div>

          <div class="px-1 m-auto sm:w-5/12">
            <label
              class="block mb-2 text-xs font-bold tracking-wide text-white uppercase"
              for="grid-tipw-doc"
            >
              Tipo de documento
            </label>
            <div>
              <select
                v-model="form.tipo_documento"
                class="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 border border-gray-200 rounded appearance-none bg-gray-50 focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option
                  v-for="tipo in tipos_documento"
                  :key="tipo"
                  :value="tipo"
                >
                  {{ tipo }}
                </option>
              </select>
            </div>
          </div>

          <div class="px-1 m-auto sm:4/12">
            <button
              @click="filtrar"
              class="p-2 font-bold text-white rounded w-28 bg-igep-dark-blue hover:bg-igep-blue focus:outline-none focus:shadow-outline"
              type="button"
            >
              Pesquisar
            </button>
          </div>
        </div>
      </form>
      <div v-if="infos_cards.length == 0">
        <h2 class="py-10 text-xl md:text-4xl b">Loading...</h2>
      </div>
      <div class="grid w-11/12 gap-10 m-auto my-10 sm:w-8/12 sm:grid-cols-2">
        <div
          v-for="(info, index) in filtered_cards"
          :key="filtered_cards[index]"
          class=""
        >
          <TransparenciaCard :infos_card="info" class="" />
        </div>
      </div>
      <div
        class="flex items-center justify-center py-2 m-auto mt-2 mb-5 text-center rounded-lg"
      >
        <div v-for="link in infos_cards.links" :key="link.index">
          <div v-if="link.url != null">
            <button @click="getNews(link.url)" :class="changeCss(link.active)">
              {{ tratarNome(link.label) }}
            </button>
          </div>
        </div>
      </div>
      <section>
        <div
          class="mx-auto bg-gradient-to-r from-igep-blue from-10% to-igep-dark-blue to-90% sm:flex sm:px-10"
        >
          <div
            v-for="arquivo in Arquivos"
            :key="arquivo.nome"
            class="mx-auto sm:px-4 pb-14"
          >
            <div
              class="grid sm:w-[350px] w-10/12 m-auto py-4 px-4 h-full grid-cols-1 sm:px-3 sm:py-2 mt-8 border-2 rounded-3xl bg-igep-dark-blue border-igep-green"
            >
              <a class="mx-auto my-3">
                <DocumentArrowDownIcon class="w-12 h-12 text-igep-green" />
              </a>
              <div class="pt-2 text-white sm:col-span-2 sm:text-xl">
                {{ arquivo.nome }}
              </div>
              <div class="col-span-2 mt-2">
                <button
                  class="px-2 py-1 text-sm rounded-lg bg-igep-green hover:bg-lime-500 text-igep-dark-blue"
                  @click="verPDF(arquivo.link)"
                >
                  {{ arquivo.descricao }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </body>

  <FooterBar />
</template>
