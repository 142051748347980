<script setup>
import "leaflet/dist/leaflet.css";
import LgpdCard from "./LgpdCard.vue";

const props = defineProps({
  msg: String,
});

const sedes = {
  sobral: {
    nome: "Sede Sobral",
    endereco_rua: "Rua Dom Lourenço, 284",
    endereco_bairro: "Campo dos Velhos",
    cidade: "Sobral/CE",
    cep: "62030-190",
    link: "https://www.google.com/maps/place/R.+Dom+Louren%C3%A7o,+284+-+Campo+dos+Velhos,+Sobral+-+CE,+62030-190/@-3.6778536,-40.3570082,121m/data=!3m1!1e3!4m6!3m5!1s0x7eac6da65a237af:0x2f77dce26b8e1b63!8m2!3d-3.6779752!4d-40.3567914!16s%2Fg%2F11tj1p1jdy?entry=ttu&g_ep=EgoyMDI0MTEwNi4wIKXMDSoASAFQAw%3D%3D",
  },
  groairas: {
    nome: "Sede Groairas",
    endereco_rua: "Rua Monsenhor Linhares, Nº 50",
    endereco_bairro: "Centro",
    cidade: "Groaíras/CE",
    cep: "62.190-000",
    link: "https://www.google.com/maps/place/R.+Monsenhor+Linhares,+50+-+Centro,+Groa%C3%ADras+-+CE,+62190-000/@-3.914468,-40.3828277,19z/data=!3m1!4b1!4m6!3m5!1s0x79534056353e3a5:0x613259a5ea3c2c4c!8m2!3d-3.914468!4d-40.382184!16s%2Fg%2F11sjvq19pf?entry=ttu&g_ep=EgoyMDI0MTEwNi4wIKXMDSoASAFQAw%3D%3D",
  },
  meruoca: {
    nome: "Sede Meruoca",
    endereco_rua: "Avenida Irineu Coutinho Aguiar, s/n",
    endereco_bairro: "Posto Meruoca - Sala 6",
    cidade: "Meruoca/CE",
    cep: "62130-000",
    link: "https://www.google.com/maps/search/Avenida+Irineu+Coutinho+Aguiar,+s%2Fn,+Meruoca+(Posto+Meruoca)+-+CE,+62130-000/@-3.5472842,-40.4530443,17z/data=!3m1!4b1?entry=ttu&g_ep=EgoyMDI0MTEwNi4wIKXMDSoASAFQAw%3D%3D",
  },
};

const zoom = 16;
var iconeEntrada =
  "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png";
var sizeIcone = [19, 27];
const markerCoords = [-3.69121, -40.35404];
</script>

<template setup>
  <LgpdCard />
  <footer class="bottom-0 flex w-full shadow-md sm:h-44 bg-igep-dark-blue">
    <div class="m-auto">
      <div class="py-1 m-auto text-gray-300 sm:space-x-20 md:flex">
        <div class="flex justify-center py-2 my-auto space-x-4 md:space-x-14">
          <!-- Whatsapp -->
          <!-- <a href="https://www.web.whattapp.com" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
              <path
                d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
            </svg>
          </a> -->

          <!--Twitter -->
          <!-- <a href="https://twitter.com/SaudeIgep" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
              <path
                d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z" />
            </svg>
          </a> -->

          <a href="https://twitter.com/SaudeIgep" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-5 h-5 text-slate-500"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z"
              />
            </svg>
            <font-awesome-icon :icon="['fab', 'twitter']" fill="currentColor" />
          </a>

          <!--Youtube -->
          <a href="https://www.youtube.com/@IgepSaude" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"
              />
            </svg>
          </a>

          <!-- Facebook -->
          <a
            href="https://www.facebook.com/profile.php?viewas=100000686899395&id=61555869082592"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"
              />
            </svg>
          </a>

          <!-- Linkedin -->
          <a
            href="https://www.linkedin.com/company/igep-sa%C3%BAde/?viewAsMember=true"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"
              />
            </svg>
          </a>

          <!-- Instagram -->
          <a href="https://www.instagram.com/igepsaude/" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
              />
            </svg>
          </a>
        </div>

        <div
          v-for="sede in Object.values(sedes)"
          class="flex-col w-[270px] text-left"
          :key="sede.nome"
        >
          <a :href="sede.link" target="_blank">
            <span class="my-4 text-xl text-white">{{ sede.nome }}</span>
            <div class="">
              <p>{{ sede.endereco_rua }}</p>
              <p>{{ sede.endereco_bairro }}</p>
              <p>{{ sede.cidade }}</p>
              <p>{{ sede.cep }}</p>
            </div>
          </a>
        </div>

        <a class="flex justify-center m-auto">
          <router-link to="/" class="flex text-lg text-gray-300 nav-link"
            >Home</router-link
          >
        </a>
      </div>
    </div>
  </footer>
</template>
