<script setup>
import { ref } from "vue";

const acceptCookies = ref(localStorage.getItem("accepted_cookies_igep"));

const saveAcceptCookies = () => {
  localStorage.setItem("accepted_cookies_igep", "true");
  acceptCookies.value = true;
};
</script>

<template setup>
  <div
    v-if="!acceptCookies"
    class="fixed bottom-0 justify-center w-full m-auto text-center"
  >
    <div class="px-3 m-3 text-xl bg-white rounded-lg shadow-xl sm:flex h-min">
      <div class="p-5">
        <div class="px-3 pt-4 text-xl font-bold text-left">
          <p>Configurações de cookies</p>
        </div>
        <div class="p-3 text-justify">
          <p>
            Este site usa cookies para garantir que você obtenha a melhor
            experiência. Ao continuar navegando, você concorda com o uso de
            cookies.
          </p>
        </div>
      </div>
      <div class="flex justify-end px-3 py-3 sm:w-1/5">
        <button
          @click="saveAcceptCookies()"
          class="p-2 px-4 m-auto text-white rounded-md bg-igep-blue"
        >
          Entendi
        </button>
      </div>
    </div>
  </div>
</template>
