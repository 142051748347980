<script setup>
import FooterBar from "@/components/FooterBar.vue";
import NavigationBar from "@/components/NavigationBar.vue";
import TranspRelatorioCard from "@/components/TranspRelatorioCard.vue";
import { DocumentTextIcon } from "@heroicons/vue/24/outline";

const items = [
  {
    title: "Município de Meruoca",
    cards: [
      {
        title: "Contrato de Gestão",
        description:
          "Contrato de Gestão entre o Município de Meruoca Nr. SS-CH003/2023",
        link: "https://drive.google.com/file/d/1cDeCOgSBGqbQO3F0PPfL_tLiKtTt3Wha/view?usp=drive_link",
      },
    ],
  },
  {
    title: "Município de Groaíras",
    cards: [
      {
        title: "Contrato de Gestão",
        description:
          "Contrato de Gestão entre o Município de Meruoca Nr. 0502.012024",
        link: "https://drive.google.com/file/d/1rTjKKYeiaDbkCxLzmD4igQLuqUN48vXY/view?usp=drive_link",
      },
    ],
  },
  {
    title: "Município de Acaraú",
    cards: [
      {
        title: "Termo de Fomento",
        description:
          "Termo de Fomento entre o Município de Acaraú e o Instituto de Saúde e Gestão Hospitalar  Nr. 0406.01/2024-SESA",
        link: "https://drive.google.com/file/d/1mljvgx_uFBcV-fZM8QXsT3vkWMRhNecO/view?usp=drive_link",
      },
      {
        title: "Termo de Fomento - Extrato de Ratificação",
        description:
          "Termo de Fomento entre o Município de Acaraú e o IGEP  Nr. 0406.01/2024-SESA - Extrato de Ratificação",
        link: "https://drive.google.com/file/d/1EjBWFNWABzMlobbNJb1P_hhJ__zUPgTd/view?usp=drive_link",
      },
      {
        title: "Contrato de Gestão",
        description:
          "Contrato de Gestão entre a UPA de Aracarú e o Instituto de Saúde e Gestão Hospitalar  Nr. 2024.04.25-001",
        link: "https://drive.google.com/file/d/1qjNm_k6qAbXXRPX_Yb4sDcoLmQnMF_J8/view?usp=drive_link",
      },
    ],
  },
];
</script>

<template>
  <NavigationBar />

  <body class="my-5 mt-20 md:mt-28">
    <div
      class="bg-gradient-to-br from-igep-dark-blue to-igep-blue h-[150px] sm:h-[280px] flex items-center"
    >
      <h1 class="flex items-start text-4xl text-white sm:ml-56 sm:items-center">
        <DocumentTextIcon class="w-10 h-10 ml-10 sm:mr-5" />
        Contratos de Gestão
      </h1>
    </div>
    <div class="w-9/12 mx-auto">
      <div class="justify-start mt-20" v-for="item in items" :key="item.title">
        <h1 class="text-xl font-bold text-start">
          {{ item.title }}
        </h1>
        <div class="grid gap-2 mt-5 sm:grid-cols-4">
          <div v-for="card in item.cards" :key="card.title" class="">
            <TranspRelatorioCard
              :title="card.title"
              :description="card.description"
              :link="card.link"
            />
          </div>
        </div>
      </div>
    </div>
  </body>
  <FooterBar />
</template>
