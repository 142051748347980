<script setup>
import { ref } from "vue";

const props = defineProps({
  infos_card: Object,
});

const detalhes = ref(false);

const formatarData = (data) => {
  if (!data) return "";

  const dia = data.split("-")[2];
  const mes = data.split("-")[1];
  const ano = data.split("-")[0];

  return `${dia}/${mes}/${ano}`;
};

const verDetalhes = () => {
  detalhes.value = !detalhes.value;
};
</script>

<template>
  <div
    class="flex justify-center p-4 m-auto text-center text-white border-2 rounded-lg shadow-lg sm:w-10/12 sm:h-48 bg-igep-dark-blue border-igep-green"
  >
    <div class="flex-col w-full">
      <div class="flex justify-between">
        <h3 class="text-sm opacity-70 text-start">
          {{ formatarData(infos_card?.data_publicacao) }}
        </h3>
      </div>
      <h1 class="my-3 text-lg text-left">{{ infos_card.titulo }}</h1>
      <div>
        <p class="text-sm text-justify" v-html="infos_card?.descricao"></p>
      </div>
    </div>
    <div class="flex flex-col justify-between">
      <div class="flex justify-end">
        <span class="px-2 bg-blue-600 rounded-sm">
          {{ infos_card?.tipo.nome }}
        </span>
      </div>
      <div class="m-auto">
        <a
          :href="infos_card?.arquivos"
          target="_blank"
          class="px-2 py-1 ml-5 text-sm rounded-md bg-igep-green hover:bg-lime-500 text-igep-dark-blue"
          >Download
        </a>
      </div>
    </div>
  </div>
</template>
