<script setup>
import axios from "axios";
import { reactive } from "vue";

const verificarSenha = () => {
  const senha = JSON.parse(localStorage.getItem("auth_pesquisa"));
  // console.log(senha);
  // if (senha == process.env.VUE_APP_AUTH_PESQUISA) {
  //   return true;
  // }
  if (senha) {
    return true;
  }
  return false;
};

const cadastrarSenha = (password) => {
  localStorage.setItem("auth_pesquisa", JSON.stringify(password));
  senha.password = password;
  window.location.reload();
  // if (password == process.env.VUE_APP_AUTH_PESQUISA) {
  //   localStorage.setItem("auth_pesquisa", JSON.stringify(password));
  //   senha.password = password;
  //   window.location.reload();
  // } else {
  //   alert("Senha incorreta");
  // }
};

const enviarPesquisa = async (state) => {
  const obj_envio = {
    titulo_pesquisa: "Pesquisa Satisfação IGEP",
    pesquisa: JSON.stringify({
      nota: state.rating,
      motivo: motivos.find((m) => m.id == state.reason)?.titulo,
      comentario: state.feedback,
    }),
    senha: JSON.parse(localStorage.getItem("auth_pesquisa")),
  };

  await axios
    .post("https://vituz.app/api/igep/pesquisa-satisfacao", obj_envio)
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.error(error);
    });
};

const getEmoji = (rating) => {
  // Função para obter o emoji correspondente à avaliação
  return emojis[rating - 1];
};

const getLegend = (value) => {
  // Função para obter a legenda correspondente ao valor da avaliação
  const legends = avaliacoes.map((avaliacao) => avaliacao.legenda);
  return legends[value - 1];
};

const newSurvey = () => {
  state.rating = null;
  state.feedback = "";
  state.submitted = false;
  state.reason = null;
  motivos.forEach((motivo) => {
    motivo.cor = "";
  });
};

const cores = [
  "#FF0000",
  "#FF3300",
  "#FF6600",
  "#FF9900",
  "#FFCC00",
  "#FFED00",
  "#D6FF33",
  "#A9FF33",
  "#7CFF33",
  "#33FF33",
];

const avaliacoes = [
  { legenda: "Péssimo", cor: cores[0], nota: 0 },
  { legenda: "Muito Ruim", cor: cores[1], nota: 1 },
  { legenda: "Ruim", cor: cores[2], nota: 2 },
  { legenda: "Regular", cor: cores[3], nota: 3 },
  { legenda: "Bom", cor: cores[4], nota: 4 },
  { legenda: "Muito Bom", cor: cores[5], nota: 5 },
  { legenda: "Amor", cor: cores[6], nota: 6 },
  { legenda: "Incrível", cor: cores[7], nota: 7 },
  { legenda: "Fantástico", cor: cores[8], nota: 8 },
  { legenda: "Maravilhoso", cor: cores[9], nota: 9 },
  { legenda: "Excelente", cor: cores[9], nota: 10 },
];
const selected_color = "#ADD8E6";

const motivos = reactive([
  { id: 1, titulo: "Atendimentos", nota: null, cor: "" },
  { id: 2, titulo: "Estrutura Física", nota: null, cor: "" },
  { id: 3, titulo: "Localização", nota: null, cor: "" },
  { id: 4, titulo: "Profissionais", nota: null, cor: "" },
  { id: 5, titulo: "Qualidade dos Serviços", nota: null, cor: "" },
]);

const perguntas = [
  {
    id: 1,
    titulo: "Como voce avalia sua experiência no instituto?",
    nota: null,
  },
];

const senha = reactive({
  password: "",
});

const state = reactive({
  rating: null,
  feedback: "",
  submitted: false,
  reason: null,
});

const verificarPreenchimento = () => {
  if (state.rating === null) {
    alert("Por favor, avalie sua experiência no instituto.");
    return false;
  }
  if (state.reason === null) {
    alert("Por favor, selecione um motivo para a sua avaliação.");
    return false;
  }
  return true;
};

const salvarMotivo = (id) => {
  motivos.forEach((motivo) => {
    if (motivo.id === id) {
      motivo.cor = selected_color;
    } else {
      motivo.cor = "";
    }
  });
  if (state.reason === id) {
    state.reason = null;
    return;
  }
  state.reason = id;
};

const checkMotivo = (id) => {
  if (state.reason == id) {
    return true;
  }
  return false;
};

const submitForm = () => {
  if (!verificarPreenchimento()) {
    return;
  }
  enviarPesquisa(state);
  state.submitted = true;
};
</script>

<template>
  <div
    id="app"
    class="flex justify-start py-8 m-auto items-left bg-gradient-to-t from-igep-blue to-blue-300 sm:from-white sm:to-white"
  >
    <div
      class="m-auto rounded-lg sm:bg-gradient-to-t sm:from-igep-blue sm:to-blue-300 sm:p-4"
    >
      <h1 class="mb-5 text-3xl font-semibold text-center">
        Pesquisa de Satisfação do Atendimento IGEP
      </h1>

      <div v-if="!verificarSenha()">
        <label
          class="flex m-auto text-xl font-bold text-center text-gray-700"
          for="senha"
          >Digite a senha para acessar a pesquisa</label
        >
        <input
          v-model="senha.password"
          id="senha"
          type="password"
          class="w-1/4 h-10 border rounded mt-7"
        />
        <button
          @click="cadastrarSenha(senha.password)"
          class="px-4 py-2 mx-4 text-black bg-blue-200 rounded"
        >
          Acessar
        </button>
      </div>
      <div v-else>
        <div v-if="!state.submitted">
          <div
            v-for="pergunta in perguntas"
            :key="pergunta.titulo"
            class="my-4 mb-4"
          >
            <div class="mt-8">
              <label
                class="flex text-xl font-bold text-center text-gray-700 lg:text-left"
                for="rating"
              >
                {{ pergunta.titulo }}
              </label>
              <div class="flex justify-between w-11/12 py-4 m-auto mt-4">
                <div
                  v-for="avaliacao in avaliacoes"
                  :key="avaliacao.nota"
                  class="flex-col"
                >
                  <div class="">
                    <label
                      :for="avaliacao.nota"
                      :style="{ backgroundColor: avaliacao.cor }"
                      class="flex justify-center pt-1 m-auto font-semibold rounded-full w-7 h-7"
                      >{{ avaliacao.nota }}
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      :id="pergunta.id"
                      :value="avaliacao.nota"
                      v-model="state.rating"
                      class="p-10"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-6">
            <label
              class="flex text-xl font-bold text-center text-gray-700 lg:text-left"
            >
              Quais os motivos que levaram à essa avaliação?
            </label>
            <div class="flex justify-between py-4 m-auto mt-4">
              <button
                v-for="motivo in motivos"
                @click="salvarMotivo(motivo.id)"
                :key="motivo.id"
                :style="{ backgroundColor: motivo.cor }"
                class="flex w-3/12 h-24 p-1 m-1 text-center bg-blue-100 rounded-md shadow-lg"
              >
                <label
                  :for="motivo"
                  class="flex pt-1 m-auto font-semibold text-center rounded-full"
                >
                  {{ motivo.titulo }}
                </label>
                <!-- <div>
                <input
                  type="radio"
                  :id="motivo.id"
                  :value="motivo.id"
                  v-model="state.reason"
                  class="flex items-end m-auto"
                />
              </div> -->
              </button>
            </div>
          </div>
          <div class="mt-6">
            <label
              class="flex m-auto text-xl font-bold text-center text-gray-700 lg:text-left"
              for="feedback"
              >Deseja incluir algum comentário ou sugestão?</label
            >
            <textarea
              v-model="state.feedback"
              id="feedback"
              rows="3"
              class="w-full border rounded h-28 mt-7"
            ></textarea>
          </div>

          <button
            @click="submitForm"
            class="px-4 py-2 text-black bg-blue-200 rounded"
          >
            Enviar
          </button>
        </div>
        <div v-else @click="newSurvey">
          <p class="mb-2 text-xl font-semibold">
            Obrigado pela sua participação!
          </p>
          <p>Sua avaliação foi {{ state.rating }}.</p>
          <p>Motivo: {{ motivos.find((m) => m.id == state.reason)?.titulo }}</p>
          <p>Comentários adicionais: {{ state.feedback }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
