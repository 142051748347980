<script setup>
import FooterBar from "@/components/FooterBar.vue";
import NavigationBar from "@/components/NavigationBar.vue";

const statuses = {
  Paid: "text-green-700 bg-green-50 ring-green-600/20",
  Withdraw: "text-gray-600 bg-gray-50 ring-gray-500/10",
  Overdue: "text-red-700 bg-red-50 ring-red-600/10",
};
const clients = [
  {
    id: 1,
    info: "Prestar serviços de assistência médica e odontológica, de administração e gestão de unidades e serviços de saúde da atenção primária, da atenção secundária e atenção terciária, nos setores médico hospitalar, odontológico, ambulatorial, diagnóstico, urgência/emergência e sanitário, com foco na economicidade e eficiência dos processos",
  },
  {
    id: 2,
    info: "Promover e incentivar o ensino e a pesquisa científica em saúde",
  },
  {
    id: 3,
    info: "Cooperar com o conhecimento, discussão e resolução dos problemas concernentes às políticas públicas de saúde",
  },
  {
    id: 4,
    info: "Cooperar com a formação de profissionais e de estudantes que atuam na área da saúde, especialmente no segmento da gestão",
  },
  {
    id: 5,
    info: "Atuar no desenvolvimento de projetos, programas e ações inovadoras em saúde, especialmente no segmento gestão",
  },
  {
    id: 6,
    info: "Promover e/ou apoiar o desenvolvimento dos trabalhadores da saúde por meio de cursos, oficinas, palestras, seminários, congressos e afins, de forma presencial ou remota, com o objetivo de avaliar processos de trabalhos, promover atualizações, produzir conhecimento e inovações com fito no aperfeiçoamento dos processos de trabalho em saúde",
  },
  {
    id: 7,
    info: "Desenvolver, validar e publicar protocolos assistenciais e gerenciais relacionados à melhoria dos serviços de saúde",
  },
  {
    id: 8,
    info: "Promover e/ou apoiar o desenvolvimento de projetos e ações coletivas visando à sensibilização e participação da comunidade em relação aos problemas de saúde",
  },
  {
    id: 9,
    info: "Oferecer assessoria técnica e consultoria em saúde, almejando à prevenção e/ou detecção de problemas e elaboração de estratégias de enfrentamento",
  },
  {
    id: 10,
    info: "Criar ou se associar a publicações para divulgação, promoção e propaganda dos trabalhos realizados pelo IGEPSaúde",
  },
  {
    id: 11,
    info: "Realizar contratos, convênios ou parcerias com instituições e/ou centros de estudos e pesquisas e/ou entidades de direito público ou privado, nacionais e estrangeiras, que tenham como foco a melhoria dos serviços de saúde",
  },
  {
    id: 12,
    info: "Elaborar, planejar e/ou assessorar projetos arquitetônicos, ambientais e de infraestrutura, realizar obras e reformas em áreas físicas ou imóveis destinados ao funcionamento de ações e serviços de saúde, seja na assistência, no diagnóstico, no ensino ou na pesquisa",
  },
  {
    id: 13,
    info: "Buscar assegurar a sustentabilidade político-econômica das ações e serviços de saúde através da gestão baseada em evidências e do aperfeiçoamento dos processos",
  },
  {
    id: 14,
    info: "Firmar Contratos de Gestão, Convênios, Termos de Parceria e Termos de Cooperação com órgãos e entidades públicas e privadas, nos níveis federal, estadual e municipal, ou com organizações da sociedade civil e pessoas físicas, assim como com empresas e instituições nacionais e internacionais a fim de cumprir suas finalidades",
  },
];
</script>

<template>
  <NavigationBar msg="" />

  <body class="mt-0 sm:mt-20 md:mt-20">
    <div class="flex w-full m-auto mt-0 mb-10 sm:mt-16 md:w-full">
      <div class="m-auto text-white md:flex">
        <div class="flex sm:py-0 sm:w-full">
          <div class="">
            <div
              class="h-screen bg-no-repeat bg-cover bg-center bg-[url('../assets/quem_somos/bg_sm.png')] sm:bg-[url('../assets/quem_somos/back_img_1.png')]">
              <div class="flex-col pt-16 text-left">
                <div class="pb-8 text-center sm:w-2/5">
                  <h1 class="pt-40 text-5xl font-bold sm:text-white sm:text-7xl">
                    IGEP Saúde
                  </h1>
                  <h1 class="mt-1 text-lg font-bold sm:text-2xl">
                    Instituto de Gestão, Estudos e Pesquisa em Saúde
                  </h1>
                  <div class="w-10/12 mx-auto sm:w-9/12">
                    <h1 class="p-3 text-sm font-semibold text-center text-white sm:text-lg sm:leading-10 sm:mt-28 mt-14">
                      O Instituto de Gestão, Estudos e Pesquisa em Saúde - IGEP Saúde
                      é uma associação sem fins lucrativos que foi criada em
                      2020. De âmbito nacional e de interesse coletivo, o IGEP
                      Saúde possui atuação específica na área da saúde.
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                class="w-6/12 py-2 pl-6 mt-8 mb-4 text-lg text-left rounded-r-full sm:mb-12 sm:pl-12 sm:mt-24 sm:text-3xl sm:w-3/12 bg-igep-blue">
                <h3 class="font-semibold text-white">O que fazemos?</h3>
              </div>

              <div class="px-8">
                <ul class="m-auto text-gray-700 list-disc sm:text-lg sm:w-8/12">
                  <li v-for="client in clients" :key="client.id">
                    <dl class="py-2 text-justify sm:mr-16">
                      <div>
                        {{ client.info }}
                      </div>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>

  <FooterBar />
</template>
