<script setup>
import FooterBar from "@/components/FooterBar.vue";
import NavigationBar from "@/components/NavigationBar.vue";
import TranspRelatorioCard from "@/components/TranspRelatorioCard.vue";
import { ClipboardDocumentCheckIcon } from "@heroicons/vue/24/outline";

const items = [
  {
    title: "IGEP Saúde",
    cards: [
      {
        title: "Código de Ética e Conduta",
        description: "Código de Ética e Conduta",
        link: "https://drive.google.com/file/d/1hHS24UJctK7pQfuqfX7_c2LMw55P0BFJ/view?usp=drive_link",
      },
    ],
  },
];
</script>

<template>
  <NavigationBar />

  <body class="flex flex-col my-5 mt-20 md:mt-28">
    <div
      class="bg-gradient-to-br from-igep-dark-blue to-igep-blue h-[150px] sm:h-[280px] flex items-center"
    >
      <h1
        class="flex items-start space-x-2 text-xl text-white sm:text-4xl sm:ml-56 sm:items-center"
      >
        <ClipboardDocumentCheckIcon class="w-10 h-10 ml-5 sm:ml-10 sm:mr-5" />
        Código de Ética e Conduta
      </h1>
    </div>
    <div class="flex flex-col my-7 sm:my-20 sm:flex-row">
      <div class="flex flex-col justify-start sm:items-start sm:mt-2 sm:ml-64">
        <h1 class="text-xl font-bold text-start px-7">
          <!-- {{ items[0].cards[0].description }} -->
        </h1>
        <div class="my-5 px-7 sm:px-0 sm:w-1/2">
          <p class="my-4 text-xl font-bold text-start">
            Apresentamos o Código de Ética e Conduta do Instituto de Gestão,
            Estudos e Pesquisas em Saúde – IGEPSaúde, um documento que
            representa não apenas um copilado de regras e condutas, mas os
            nossos valores defendidos frente a sociedade a qual fazemos parte.
          </p>
          <p class="my-4 text-xl font-bold text-start">
            Reafirmamos aqui o nosso compromisso ético em nossas relações
            externas e internas, fomentando sempre a integridade entre as partes
            interessadas, elemento fundamental para os princípios da boa
            governança.
          </p>
        </div>
        <div class="w-[350px] mt-5 m-auto sm:m-1">
          <TranspRelatorioCard
            :title="items[0].title"
            :description="items[0].cards[0].description"
            :link="items[0].cards[0].link"
          />
        </div>
      </div>
    </div>
  </body>
  <FooterBar />
</template>
