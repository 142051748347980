<script setup>
import FooterBar from "@/components/FooterBar.vue";
import NavigationBar from "@/components/NavigationBar.vue";
import TranspRelatorioCard from "@/components/TranspRelatorioCard.vue";
import { ClipboardDocumentCheckIcon } from "@heroicons/vue/24/outline";

const items = [
  {
    title: "IGEP Saúde",
    cards: [
      {
        title: "Manual do RH",
        description:
          "MANUAL DE RECRUTAMENTO, SELEÇÃO, ADMISSÃO E RECISSÃO DE PESSOAL",
        link: "https://drive.google.com/file/d/1j9E2NYYDBvyl34M6ap562GTQJoMSEVAz/view?usp=drive_link",
      },
      {
        title: "Manual de Compras",
        description:
          "MANUAL E REGULAMENTO DE COMPRAS E CONTRATAÇÃO DE SERVIÇOS",
        link: "https://drive.google.com/file/d/1IwK6DWiCkgoc0yVO98e2KArA3JxX10US/view?usp=drive_link",
      },
    ],
  },
];
</script>

<template>
  <NavigationBar />

  <body class="flex flex-col my-5 mt-20 md:mt-28">
    <div
      class="bg-gradient-to-br from-igep-dark-blue to-igep-blue h-[150px] sm:h-[280px] flex items-center"
    >
      <h1 class="flex items-start text-4xl text-white sm:ml-56 sm:items-center">
        <ClipboardDocumentCheckIcon class="w-10 h-10 ml-10 sm:mr-5" />
        Manuais
      </h1>
    </div>
    <div class="flex flex-col items-start m-auto sm:w-9/12 my-7 sm:my-2">
      <div
        v-for="card in items[0].cards"
        :key="card.title"
        class="flex flex-col mt-5 sm:items-start sm:mt-20"
      >
        <h1 class="text-lg font-bold sm:text-2xl text-start">
          {{ card.title }}
        </h1>
        <div class="w-[350px] m-auto mt-7">
          <TranspRelatorioCard
            :description="card.description"
            :link="card.link"
          />
        </div>
      </div>
    </div>
  </body>
  <FooterBar />
</template>
