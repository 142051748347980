<template>
  <div
    class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50"
  >
    <div class="w-full max-w-sm p-8 bg-white rounded shadow-md">
      <h2 class="mb-6 text-2xl font-semibold text-center text-blue-500">
        Login
      </h2>
      <form @submit.prevent="submitForm">
        <div class="mb-4">
          <label for="username" class="block text-sm font-medium text-gray-700"
            >Usuário:</label
          >
          <input
            type="text"
            id="username"
            v-model="username"
            class="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            required
          />
        </div>
        <div class="mb-6">
          <label for="password" class="block text-sm font-medium text-gray-700"
            >Senha:</label
          >
          <input
            type="password"
            id="password"
            v-model="password"
            class="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            required
          />
        </div>
        <button
          type="submit"
          class="w-full px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
        >
          Entrar
        </button>
      </form>
      <button
        class="w-full px-4 py-2 mt-4 text-gray-700 bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-400"
        @click="closeModal"
      >
        Fechar
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref } from "vue";

const showModal = ref(false);
const username = ref("");
const password = ref("");

const props = defineProps({
  showModal: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["formSubmitted"]);

const submitForm = () => {
  emits("receiveData", {
    username: username.value,
    password: password.value,
    showModal: false,
  });
  showModal.value = false;
};

const closeModal = () => {
  emits("receiveData", {
    username: "",
    password: "",
    showModal: false,
  });
};
</script>
