<script setup>
// @ is an alias to /src
import FooterBar from "@/components/FooterBar.vue";
import NavigationBar from "@/components/NavigationBar.vue";
import {
  CheckBadgeIcon,
  ClipboardDocumentCheckIcon,
  CubeTransparentIcon,
  GlobeAmericasIcon,
  ScaleIcon,
  UserPlusIcon,
} from "@heroicons/vue/24/outline";

const features = [
  {
    name: "Excelência",
    description: "",
    icon: ClipboardDocumentCheckIcon,
  },
  {
    name: "Inclusão Social e Empatia",
    description: "",
    icon: UserPlusIcon,
  },
  {
    name: "⁠Responsabilidade Ambiental",
    description: "",
    icon: GlobeAmericasIcon,
  },
  {
    name: "Transparência",
    description: "",
    icon: CheckBadgeIcon,
  },
  {
    name: "⁠Conhecimento Baseado em Evidência e Inovação",
    description: "",
    icon: CubeTransparentIcon,
  },
  {
    name: "⁠Ética e Integridade",
    description: "",
    icon: ScaleIcon,
  },
];
const posts = [
  {
    id: 2,
    title: "Missão",
    description:
      "Ser reconhecido no estado do Ceará como um instituto de referência em inovação e tecnologia na gestão da saúde, proporcionando conhecimento baseado em evidências, qualidade de vida e comprometimento social.",
  },

  {
    id: 3,
    title: "Visão",
    description:
      "Prover gestão de saúde com excelência, inspirando e implementando soluções inovadoras em saúde buscando o bem-estar comum da sociedade.",
  },
];
</script>

<template>
  <div class="principios">
    <NavigationBar />

    <body>
      <div class="py-8 mt-12 text-center bg-white sm:py-16">
        <div class="mx-auto lg:px-8">
          <div class="pb-8 border-b border-gray-200 lg:mx-0">
            <h2
              class="mx-4 mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:mt-32 sm:text-4xl"
            >
              Construindo a saúde do futuro
            </h2>
            <p class="mt-2 mb-2 text-lg leading-8 text-gray-600 sm:mb-16">
              Conheça nossos principais valores e missão:
            </p>
          </div>
          <div
            class="grid w-7/12 grid-cols-1 mx-auto mt-4 gap-x-8 sm:gap-y-16 gap-y-4 sm:mt-16 lg:grid-cols-2"
          >
            <article
              v-for="post in posts"
              :key="post.id"
              class="flex flex-col items-start justify-between max-w-xl"
            >
              <div class="relative group">
                <h3
                  class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600"
                >
                  <a :href="post.href">
                    <span class="absolute inset-0" />
                    {{ post.title }}
                  </a>
                </h3>
                <p class="mt-5 text-sm leading-6 text-gray-600 line-clamp-4">
                  {{ post.description }}
                </p>
              </div>
            </article>
          </div>
        </div>
      </div>
      <div class="pt-8 pb-16 bg-white border-t border-gray-200">
        <div class="px-6 mx-auto lg:px-8">
          <div class="text-center">
            <p
              class="mt-2 mb-12 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
            >
              Valores
            </p>
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 sm:mx-8">
            <div class="col-span-1 ml-8 mr-16">
              <img
                class="shadow-lg rounded-3xl"
                src="../assets/principios/img_valores.png"
                alt="foto valores"
              />
            </div>
            <div class="col-span-1 mx-auto mt-16 sm:mt-20">
              <dl
                class="grid grid-cols-1 sm:mr-32 gap-x-8 gap-y-8 lg:max-w-none"
              >
                <div
                  v-for="feature in features"
                  :key="feature.name"
                  class="relative pl-16"
                >
                  <dt class="text-base font-semibold leading-7 text-gray-900">
                    <div
                      class="absolute top-0 left-0 flex items-center justify-center w-10 h-10 rounded-lg bg-igep-blue"
                    >
                      <component
                        :is="feature.icon"
                        class="w-6 h-6 text-white"
                        aria-hidden="true"
                      />
                    </div>

                    <div class="text-start">
                      {{ feature.name }}
                    </div>
                  </dt>
                  <dd class="mt-2 text-base text-gray-600 text-start">
                    {{ feature.description }}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </body>
    <FooterBar />
  </div>
</template>
