<script setup>
import router from "@/router";
// import { Cog8ToothIcon } from "@heroicons/vue/24/outline";
import { ref, watch } from "vue";
import LoginModal from "./LoginModal.vue";

const menu_icon =
  '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-menu-down" viewBox="0 0 16 16"> <path d="M7.646.146a.5.5 0 0 1 .708 0L10.207 2H14a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h3.793L7.646.146zM1 7v3h14V7H1zm14-1V4a1 1 0 0 0-1-1h-3.793a1 1 0 0 1-.707-.293L8 1.207l-1.5 1.5A1 1 0 0 1 5.793 3H2a1 1 0 0 0-1 1v2h14zm0 5H1v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2zM2 4.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/> </svg>';

let dropdown = ref(false);
const showModal = ref(false);

watch(() => {
  dropdown;
});

const menus = [
  {
    title: "Home",
    path: "/",
    submenus: [],
  },
  {
    title: "Quem Somos",
    path: "/quem-somos",
    submenus: [],
  },
  {
    title: "Princípios",
    path: "/principios",
    submenus: [],
  },
  {
    title: "Notícias",
    path: "/noticias",
    submenus: [],
  },
  {
    title: "Transparência",
    path: "/transparencia",
    submenus: [
      {
        title: "Contratos de Gestão",
        path: "/contratos-gestao",
      },
      {
        title: "Código de Conduta",
        path: "/codigo-conduta",
      },
      {
        title: "Manuais",
        path: "/manuais",
      },
      // {
      //   title: "Leis e Decretos",
      //   path: "/leis-decretos",
      // },
      // {
      //   title: "Certificações",
      //   path: "/certificacoes",
      // },
      // {
      //   title: "Relatórios de Gestão",
      //   path: "/relatorios-gestao",
      // },
    ],
  },
  {
    title: "Pesquisa",
    path: "/pesquisa",
    submenus: [],
  },
  {
    title: "Contatos",
    path: "/contatos",
    submenus: [],
  },
];

const solicitarAutenticacao = () => {
  showModal.value = true;
};

const handleFormSubmitted = (data) => {
  if (
    data.username == process.env.VUE_APP_USER_ADMIN &&
    data.password == process.env.VUE_APP_PASSWORD_ADMIN
  ) {
    router.push("/painel-administrativo");
  } else if (data.username == "" && data.password == "") {
    showModal.value = data?.showModal;
  } else {
    alert("Usuário ou senha inválidos!");
    router.push("/");
  }
  showModal.value = data?.showModal;
};
</script>

<template>
  <header>
    <div
      class="fixed top-0 flex w-full h-16 shadow-xl bg-igep-medium-gray md:h-28"
    >
      <div>
        <div class="md:flex">
          <div class="">
            <img
              src="../assets/logo_igep2.png"
              alt="Logo"
              class="w-40 py-2 pl-2 md:py-3 md:pl-5 md:w-80"
            />
          </div>
          <div
            :class="
              !dropdown
                ? 'hidden flex-col md:pt-3 md:pl-10 md:flex text-sm rounded-md md:rounded-none md:border-none border-black border-solid'
                : 'flex-col md:pt-3 md:pl-36 md:flex text-sm bg-igep-medium-gray bg-opacity-95 rounded-md md:rounded-none md:border-none border-black border-solid'
            "
          >
            <nav class="md:ml-28 md:flex text-igep-dark-blue">
              <div
                class="py-1 sm:flex-col md:py-0 group"
                v-for="menu in menus"
                :key="menu.path"
              >
                <router-link
                  :to="menu.path"
                  class="text-sm hover:text-2xl md:py-0 md:text-xl md:px-4"
                  >{{ menu.title }}
                </router-link>

                <div
                  class="flex-col hidden py-3 mt-2 border-2 rounded-md w-[240px] text-center border-igep-green bg-igep-dark-blue bg-opacity-90 group-hover:flex"
                  v-if="menu.submenus.length > 0"
                >
                  <div v-for="submenu in menu?.submenus" :key="submenu.path">
                    <router-link
                      :to="submenu.path"
                      class="text-xs text-white hover:border-y hover:border-y-slate-400 hover:text-xl md:py-0 md:text-base md:px-4"
                    >
                      {{ submenu.title }}
                    </router-link>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <!-- <div class="flex items-center justify-end">
        <button @click="solicitarAutenticacao" class="flex">
          <Cog8ToothIcon class="w-10 h-10 mr-5" />
        </button>
      </div> -->
      <div class="m-auto mr-6">
        <button @click="dropdown = !dropdown" class="flex md:hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="currentColor"
            class="bi bi-menu-down"
            viewBox="0 0 16 16"
          >
            <path
              d="M7.646.146a.5.5 0 0 1 .708 0L10.207 2H14a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h3.793L7.646.146zM1 7v3h14V7H1zm14-1V4a1 1 0 0 0-1-1h-3.793a1 1 0 0 1-.707-.293L8 1.207l-1.5 1.5A1 1 0 0 1 5.793 3H2a1 1 0 0 0-1 1v2h14zm0 5H1v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2zM2 4.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </button>
      </div>

      <div></div>
    </div>
  </header>
  <LoginModal
    v-if="showModal"
    @close-modal="showModal = false"
    @receiveData="handleFormSubmitted"
  />
</template>
