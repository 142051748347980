<script setup>
import FooterBar from "@/components/FooterBar.vue";
import NavigationBar from "@/components/NavigationBar.vue";
import TranspRelatorioCard from "@/components/TranspRelatorioCard.vue";
import { CheckBadgeIcon } from "@heroicons/vue/24/outline";

const items = [
  {
    title: "HRC - Hospital Regional do Cariri",
    cards: [
      {
        title: "Relatório de Transparência e Igualdade Salarial",
        description:
          "Relatório de Transparência e Igualdade Salarial de Mulheres e Homens - 1º Semestre 2024",
        link: "https://media.starlightcms.io/workspaces/isgh/isgh/original/relatorio-de-transparenciapdf-ehtnebk9om.pdf",
      },
      {
        title: "Relatório de Transparência e Igualdade Salarial",
        description:
          "Relatório de Transparência e Igualdade Salarial de Mulheres e Homens - 1º Semestre 2024",
        link: "https://media.starlightcms.io/workspaces/isgh/isgh/original/relatorio-de-transparenciapdf-ehtnebk9om.pdf",
      },
    ],
  },
  {
    title: "ISGH - Instituto de Saúde e Gestão Hospitalar",
    cards: [
      {
        title: "Decreto nº 29.555",
        description:
          "Dispõe sobre a qualificação do Instituto de Saúde e Gestão Hospitalar - ISGH como Organização Social de Saúde - OSS, no âmbito do Estado do Ceará, e dá outras providências.",
        link: "https://www.isgh.org.br/wp-content/uploads/2021/12/Decreto-29.555.pdf",
      },
      {
        title: "Decreto nº 29.555",
        description:
          "Dispõe sobre a qualificação do Instituto de Saúde e Gestão Hospitalar - ISGH como Organização Social de Saúde - OSS, no âmbito do Estado do Ceará, e dá outras providências.",
        link: "https://www.isgh.org.br/wp-content/uploads/2021/12/Decreto-29.555.pdf",
      },
      {
        title: "Decreto nº 29.555",
        description:
          "Dispõe sobre a qualificação do Instituto de Saúde e Gestão Hospitalar - ISGH como Organização Social de Saúde - OSS, no âmbito do Estado do Ceará, e dá outras providências.",
        link: "https://www.isgh.org.br/wp-content/uploads/2021/12/Decreto-29.555.pdf",
      },
      {
        title: "Decreto nº 29.555",
        description:
          "Dispõe sobre a qualificação do Instituto de Saúde e Gestão Hospitalar - ISGH como Organização Social de Saúde - OSS, no âmbito do Estado do Ceará, e dá outras providências.",
        link: "https://www.isgh.org.br/wp-content/uploads/2021/12/Decreto-29.555.pdf",
      },
      {
        title: "Decreto nº 29.555",
        description:
          "Dispõe sobre a qualificação do Instituto de Saúde e Gestão Hospitalar - ISGH como Organização Social de Saúde - OSS, no âmbito do Estado do Ceará, e dá outras providências.",
        link: "https://www.isgh.org.br/wp-content/uploads/2021/12/Decreto-29.555.pdf",
      },
    ],
  },
];
</script>

<template>
  <NavigationBar />

  <body class="my-5 mt-20 md:mt-28">
    <div class="bg-gradient-to-br from-igep-dark-blue to-igep-blue h-[280px] flex items-center">
      <h1 class="flex items-center ml-56 text-4xl text-white">
        <CheckBadgeIcon class="w-10 h-10 mr-5" />
        Certificações
      </h1>
    </div>
    <div class="mx-auto sm:w-11/12 lg:w-10/12">
      <div class="justify-start mt-20" v-for="item in items" :key="item.title">
        <h1 class="text-xl font-bold text-start">
          {{ item.title }}
        </h1>
        <div class="grid grid-cols-4 gap-2 mt-5">
          <div v-for="card in item.cards" :key="card.title" class="">
            <TranspRelatorioCard :title="card.title" :description="card.description" :link="card.link" />
          </div>
        </div>
      </div>
    </div>
  </body>
  <FooterBar />
</template>
