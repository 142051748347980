<script setup>
import FooterBar from "@/components/FooterBar.vue";
import NavigationBar from "@/components/NavigationBar.vue";
import TranspRelatorioCard from "@/components/TranspRelatorioCard.vue";
import { ScaleIcon } from "@heroicons/vue/24/solid";

const items = [
  {
    title: "RELATÓRIO DE TRANSPARÊNCIA SALARIAL",
    cards: [
      {
        title: "Relatório de Transparência e Igualdade Salarial",
        description:
          "Relatório de Transparência e Igualdade Salarial de Mulheres e Homens - 1º Semestre 2024",
        link: "https://media.starlightcms.io/workspaces/isgh/isgh/original/relatorio-de-transparenciapdf-ehtnebk9om.pdf",
      },
      {
        title: "Relatório de Transparência e Igualdade Salarial",
        description:
          "Relatório de Transparência e Igualdade Salarial de Mulheres e Homens - 1º Semestre 2024",
        link: "https://media.starlightcms.io/workspaces/isgh/isgh/original/relatorio-de-transparenciapdf-ehtnebk9om.pdf",
      },
    ],
  },
  {
    title: "QUALIFICAÇÃO DO ISGH NO ESTADO DO CEARÁ",
    cards: [
      {
        title: "Decreto nº 29.555",
        description:
          "Dispõe sobre a qualificação do Instituto de Saúde e Gestão Hospitalar - ISGH como Organização Social de Saúde - OSS, no âmbito do Estado do Ceará, e dá outras providências.",
        link: "https://www.isgh.org.br/wp-content/uploads/2021/12/Decreto-29.555.pdf",
      },
      {
        title: "Decreto nº 29.555",
        description:
          "Dispõe sobre a qualificação do Instituto de Saúde e Gestão Hospitalar - ISGH como Organização Social de Saúde - OSS, no âmbito do Estado do Ceará, e dá outras providências.",
        link: "https://www.isgh.org.br/wp-content/uploads/2021/12/Decreto-29.555.pdf",
      },
      {
        title: "Decreto nº 29.555",
        description:
          "Dispõe sobre a qualificação do Instituto de Saúde e Gestão Hospitalar - ISGH como Organização Social de Saúde - OSS, no âmbito do Estado do Ceará, e dá outras providências.",
        link: "https://www.isgh.org.br/wp-content/uploads/2021/12/Decreto-29.555.pdf",
      },
      {
        title: "Decreto nº 29.555",
        description:
          "Dispõe sobre a qualificação do Instituto de Saúde e Gestão Hospitalar - ISGH como Organização Social de Saúde - OSS, no âmbito do Estado do Ceará, e dá outras providências.",
        link: "https://www.isgh.org.br/wp-content/uploads/2021/12/Decreto-29.555.pdf",
      },
      {
        title: "Decreto nº 29.555",
        description:
          "Dispõe sobre a qualificação do Instituto de Saúde e Gestão Hospitalar - ISGH como Organização Social de Saúde - OSS, no âmbito do Estado do Ceará, e dá outras providências.",
        link: "https://www.isgh.org.br/wp-content/uploads/2021/12/Decreto-29.555.pdf",
      },
    ],
  },
];
</script>

<template>
  <NavigationBar />

  <body class="my-5 mt-20 md:mt-28">
    <div class="bg-gradient-to-br from-igep-dark-blue to-igep-blue h-[280px] flex items-center">
      <h1 class="flex items-center ml-56 text-4xl text-white">
        <ScaleIcon class="w-10 h-10 mr-5" />
        Leis e Decretos
      </h1>
    </div>
    <div class="w-9/12 mx-auto">
      <div>
        <h3 class="w-8/12 m-auto text-justify mt-28">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </h3>
        <h3 class="w-8/12 m-auto mt-20 text-justify">
          It has survived not only five centuries, but also the leap into
          electronic typesetting, remaining essentially unchanged. It was
          popularised in the 1960s with the release of Letraset sheets
          containing Lorem Ipsum passages, and more recently with desktop
          publishing software like Aldus PageMaker including versions of Lorem
          Ipsum.
        </h3>
      </div>
      <div class="mx-auto mt-10 sm:w-12/12" v-for="item in items" :key="item.title">
        <h1 class="text-xl font-bold text-start">
          {{ item.title }}
        </h1>
        <div class="grid grid-cols-4 gap-2 mt-5">
          <div v-for="card in item.cards" :key="card.title" class="">
            <TranspRelatorioCard :title="card.title" :description="card.description" :link="card.link" />
          </div>
        </div>
      </div>
    </div>
  </body>
  <FooterBar />
</template>
